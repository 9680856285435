import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const tiers = [
  {
    title: "Free",
    price: "£0",
    description: [
      "5x AI Question Searches Per Week",
      "1x AI Exam Paper Per Week",
      "1x AI Exam Correction",
      "2x AI Mnemonic Creation Per Week",
      "2x AI Flashcard Generation Per Week",
      "Exam Paper Downloader",
    ],
    buttonText: "Continue",
    buttonVariant: "outlined",
    buttonLink: "/mainpage",
  },
  {
    title: "Premium",
    monthlyPrice: "£4.49",
    yearlyPrice: "£39.99",
    description: [
      "Recommended",
      "Unlimited AI Question Searches",
      "Unlimited AI Exam Papers",
      "IAI and Old Syllabus Exams",
      "Premium Search Filters and Parameters",
      "1x AI Exam Correction per day",
      "Better correction model for more accuracy",
      "Unlimited Mnemonic Creation",
      "Unlimited AI Flashcard Generation",
      "Exam Paper Downloader",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
    popular: true,
    buttonLink: "",
  },
  {
    title: "Enterprise",
    price: "Contact Us",
    description: [
      "Provide your actuarial student employees with the newest study tool",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonLink: "/contactpage",
  },
];

export default function PricingTable() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [billingCycle, setBillingCycle] = useState("monthly");

  const handleBillingCycleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCycle: string | null
  ) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  const handlePremiumClick = async () => {
    setLoading(true);

    // Function to show error
    const showError = (message: string) => {
      alert(
        message ||
          `An error has occurred. Please try again. If this issue persists, please contact support@actq.co.uk`
      );
    };

    try {
      // Get the current user
      const {
        data: { user },
      } = await supabase.auth.getUser();

      // Get the current session
      const {
        data: { session },
      } = await supabase.auth.getSession();

      // Check if user and session are valid
      if (!user || !user.id) {
        showError("User not authenticated. Please log in and try again.");
        setLoading(false);
        return;
      }

      if (!session || !session.access_token) {
        showError("No session found. Please log in and try again.");
        setLoading(false);
        return;
      }

      const userId = user.id;

      // Perform the request to create Stripe checkout session
      const response = await fetch(
        "https://pfgjagzspzakuipgeowf.supabase.co/functions/v1/create_stripe_checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ id: userId }),
        }
      );

      // Handle the response
      if (!response.ok) {
        console.error("Error response:", response);
        showError(
          `An error has occurred. Status: ${response.status}. Please try again. If this occurs again, please contact support@actq.co.uk.`
        );
        setLoading(false);
        return;
      }

      const result = await response.json();

      // Check if the URL exists
      if (result.url) {
        window.location.href = result.url;
      } else {
        showError("Failed to retrieve the checkout URL. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in handlePremiumClick:", error);
      showError(
        `An error has occurred. Please try again. If this occurs again, please contact support@actq.co.uk.`
      );
      setLoading(false);
    }
  };

  return (
    <Container
      id="pricing"
      sx={{
        py: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" color="text.primary" align="center" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Choose the plan that fits your needs.
      </Typography>

      <ToggleButtonGroup
        value={billingCycle}
        exclusive
        onChange={handleBillingCycleChange}
        aria-label="billing cycle"
        sx={{
          mb: 3,
          "& .MuiToggleButton-root": {
            color: "white",
          },
          "& .Mui-selected": {
            color: theme.palette.primary.main,
          },
        }}
        color="primary"
      >
        <ToggleButton value="monthly" aria-label="monthly">
          Monthly
        </ToggleButton>
        <ToggleButton value="yearly" aria-label="yearly">
          Yearly (Over 25% Savings)
        </ToggleButton>
      </ToggleButtonGroup>

      <Grid container spacing={3} justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 3,
                boxShadow: tier.popular
                  ? `0px 4px 20px ${theme.palette.primary.main}`
                  : `0px`,
                background: tier.popular
                  ? `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`
                  : `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              }}
            >
              <Typography variant="h6" align="center">
                {tier.title}
              </Typography>
              <Typography variant="h2" align="center" gutterBottom>
                {tier.title === "Premium"
                  ? billingCycle === "yearly"
                    ? tier.yearlyPrice
                    : tier.monthlyPrice
                  : tier.price}{" "}
              </Typography>
              <Box>
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="subtitle2">{line}</Typography>
                  </Box>
                ))}
              </Box>
              {tier.title === "Premium" ? (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={handlePremiumClick}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Processing..." : tier.buttonText}
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    color: tier.popular
                      ? theme.palette.primary.contrastText
                      : theme.palette.secondary.contrastText,
                    backgroundColor: tier.popular
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: tier.popular
                        ? theme.palette.primary.dark
                        : theme.palette.secondary.dark,
                    },
                  }}
                  href={tier.buttonLink}
                  disabled={loading}
                >
                  {tier.buttonText}
                </Button>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
