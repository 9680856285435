import { useState, useEffect } from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import IFOAModern from "../../assets/IFOA_Modern.json";
import IFOAOld from "../../assets/IFOA_Old.json";
import IAI from "../../assets/IAI.json";

const monthData: { [key: string]: string } = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};

const examData: {
  Exam: string;
  Old: string[];
  DisplayName: string;
  TwoPapers: boolean;
  Group: string;
}[] = [
  {
    Exam: "CS1",
    Old: ["CT3"],
    DisplayName: "CS1 (CT3)",
    TwoPapers: true,
    Group: "Core Principles",
  },
  {
    Exam: "CS2",
    Old: ["CT4", "CT6"],
    DisplayName: "CS2 (CT4 + CT6)",
    TwoPapers: true,
    Group: "Core Principles",
  },
  {
    Exam: "CM1",
    Old: ["CT1", "CT5"],
    DisplayName: "CM1 (CT1 + CT5)",
    TwoPapers: true,
    Group: "Core Principles",
  },
  {
    Exam: "CM2",
    Old: ["CT8"],
    DisplayName: "CM2 (CT8)",
    TwoPapers: true,
    Group: "Core Principles",
  },
  {
    Exam: "CB1",
    Old: ["CT2"],
    DisplayName: "CB1 (CT2)",
    TwoPapers: false,
    Group: "Core Principles",
  },
  {
    Exam: "CB2",
    Old: ["CT7"],
    DisplayName: "CB2 (CT7)",
    TwoPapers: false,
    Group: "Core Principles",
  },
  {
    Exam: "CP1",
    Old: ["CA1"],
    DisplayName: "CP1 (CA1)",
    TwoPapers: true,
    Group: "Core Practices",
  },
  {
    Exam: "CP2",
    Old: ["CA2"],
    DisplayName: "CP2 (CA2)",
    TwoPapers: true,
    Group: "Core Practices",
  },
  {
    Exam: "CP3",
    Old: ["CA3"],
    DisplayName: "CP3 (CA3)",
    TwoPapers: false,
    Group: "Core Practices",
  },
  {
    Exam: "SP1",
    Old: ["ST1"],
    DisplayName: "SP1 (ST1)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP2",
    Old: ["ST2"],
    DisplayName: "SP2 (ST2)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP4",
    Old: ["ST4"],
    DisplayName: "SP4 (ST4)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP5",
    Old: ["ST5"],
    DisplayName: "SP5 (ST5)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP6",
    Old: ["ST6"],
    DisplayName: "SP6 (ST6)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP7",
    Old: ["ST7"],
    DisplayName: "SP7 (ST7)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP8",
    Old: ["ST8"],
    DisplayName: "SP8 (ST8)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SP9",
    Old: ["ST9"],
    DisplayName: "SP9 (ST9)",
    TwoPapers: false,
    Group: "Specialist Principles",
  },
  {
    Exam: "SA1",
    Old: ["SA1"],
    DisplayName: "SA1",
    TwoPapers: false,
    Group: "Specialist Advanced",
  },
  {
    Exam: "SA2",
    Old: ["SA2"],
    DisplayName: "SA2",
    TwoPapers: false,
    Group: "Specialist Advanced",
  },
  {
    Exam: "SA3",
    Old: ["SA3"],
    DisplayName: "SA3",
    TwoPapers: false,
    Group: "Specialist Advanced",
  },
  {
    Exam: "SA4",
    Old: ["SA4"],
    DisplayName: "SA4",
    TwoPapers: false,
    Group: "Specialist Advanced",
  },
  {
    Exam: "SA7",
    Old: ["SA5", "SA6"],
    DisplayName: "SA7 (SA5 + SA6)",
    TwoPapers: false,
    Group: "Specialist Advanced",
  },
];

interface IFOAModern {
  Exam: string;
  Year: string;
  Sitting: string;
  ExamTitle: string;
  File: string;
}

interface IFOAOld {
  Title: string;
  File: string;
}

interface IAI {
  ExamTitle: string;
  Year: string;
  Sitting: string;
  Exam: string;
  ExamPaper: string;
  Solutions: string;
}

interface ExamChangeEvent {
  target: {
    value: string;
  };
}

const ExamDownloader = () => {
  const theme = useTheme();
  const [institute, setInstitute] = useState("IFOA");
  const [selectedExam, setSelectedExam] = useState<string>("CS1");
  const [IFOAModernExamLinks, setIFOAModernExamLinks] = useState<IFOAModern[]>(
    []
  );
  const [IFOAOldExamLinks, setIFOAOldExamLinks] = useState<IFOAOld[]>([]);
  const [IAIExamLinks, setIAIExamLinks] = useState<IAI[]>([]);

  useEffect(() => {
    if (institute === "IFOA") {
      const modernLinks = IFOAModern.filter(
        (entry) => entry.ExamTitle === selectedExam
      );
      setIFOAModernExamLinks(modernLinks);

      const selectedExamData = examData.find(
        (exam) => exam.Exam === selectedExam
      );
      if (selectedExamData) {
        const filteredOldLinks = IFOAOld.filter((entry) =>
          selectedExamData.Old.includes(entry.Title)
        );
        setIFOAOldExamLinks(filteredOldLinks);
      } else {
        setIFOAOldExamLinks([]);
      }
    } else {
      const selectedExamData = examData.find(
        (exam) => exam.Exam === selectedExam
      );
      const iaiLinks = IAI.filter(
        (entry) =>
          entry.ExamTitle === selectedExam ||
          (selectedExamData && selectedExamData.Old.includes(entry.ExamTitle))
      );
      setIAIExamLinks(iaiLinks);
      setIFOAModernExamLinks([]);
      setIFOAOldExamLinks([]);
    }
  }, [institute, selectedExam]);

  const handleInstituteChange = (
    _: React.MouseEvent<HTMLElement>,
    newInstitute: string | null
  ) => {
    if (newInstitute !== null) {
      setInstitute(newInstitute);
    }
  };

  const handleExamChange = (event: ExamChangeEvent) => {
    setSelectedExam(event.target.value);
  };

  const getPaperLabel = (exam: string) => {
    if (
      ["CS1A", "CS2A", "CM1A", "CM2A", "CP1 1", "CP2 1", "CA1 1"].includes(exam)
    ) {
      return "Paper 1";
    } else if (
      ["CS1B", "CS2B", "CM1B", "CM2B", "CP1 2", "CP2 2", "CA1 2"].includes(exam)
    ) {
      return "Paper 2";
    }
    return "";
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p={3}
      sx={{
        backgroundColor: theme.palette.grey[900],
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.text.primary }}
      >
        Exam Downloader
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={institute}
        exclusive
        onChange={handleInstituteChange}
        size="large"
      >
        <ToggleButton value="IFOA" sx={{ color: "white" }}>
          IFOA
        </ToggleButton>
        <ToggleButton value="IAI" sx={{ color: "white" }}>
          IAI
        </ToggleButton>
      </ToggleButtonGroup>

      {[
        "Core Principles",
        "Core Practices",
        "Specialist Principles",
        "Specialist Advanced",
      ].map((group) => (
        <FormControl
          component="fieldset"
          style={{ marginTop: "20px" }}
          key={group}
        >
          <Typography variant="h6">{group}</Typography>
          <RadioGroup
            row
            value={selectedExam}
            onChange={handleExamChange}
            style={{ justifyContent: "center" }}
          >
            {examData
              .filter((exam) => exam.Group === group)
              .map((exam) => (
                <FormControlLabel
                  key={exam.Exam}
                  value={exam.Exam}
                  control={<Radio />}
                  label={exam.DisplayName}
                />
              ))}
          </RadioGroup>
        </FormControl>
      ))}

      {/* IFOA Modern Exams Section */}
      {institute === "IFOA" && IFOAModernExamLinks.length > 0 && (
        <Box mt={3} sx={{ width: "60%" }}>
          <Typography variant="h6" component="h2" gutterBottom>
            IFOA Modern Syllabus from 2019
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              {IFOAModernExamLinks.map((link) => (
                <Grid item xs={12} sm={6}>
                  <Button
                    key={link.File}
                    href={`https://actuaries.org.uk${link.File}`}
                    target="_blank"
                    variant="contained"
                    style={{ margin: "5px" }}
                    fullWidth
                  >
                    {link.Year} {monthData[link.Sitting] || "Unknown"}{" "}
                    {getPaperLabel(link.Exam)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}

      {/* IFOA Old Exams Section */}
      {institute === "IFOA" && IFOAOldExamLinks.length > 0 && (
        <Box mt={3} sx={{ width: "60%" }}>
          <Typography variant="h6" component="h2" gutterBottom>
            IFOA Old Syllabus from 2005 to 2018
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mb: 2,
            }}
          >
            {IFOAOldExamLinks.map((link) => (
              <Button
                key={link.File}
                href={"https://actuaries.org.uk/" + link.File}
                target="_blank"
                variant="contained"
                color="secondary"
                style={{ margin: "5px" }}
              >
                {link.Title}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      {/* IAI Exams Section */}
      {institute === "IAI" && IAIExamLinks.length > 0 && (
        <Box mt={3} sx={{ width: "60%" }}>
          <Typography variant="h6" component="h2" gutterBottom>
            IAI Modern Syllabus from 2019
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              {IAIExamLinks.filter((link) => link.Year >= "2019").map(
                (link) => (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Button
                        key={link.ExamPaper}
                        href={`https://actuariesindia.org${link.ExamPaper}`}
                        target="_blank"
                        variant="contained"
                        style={{ margin: "5px" }}
                        fullWidth
                        disabled={!link.Solutions}
                      >
                        {link.Year} {monthData[link.Sitting] || "Unknown"} -{" "}
                        {link.ExamTitle} {getPaperLabel(link.Exam)} - Exam{" "}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        href={`https://actuariesindia.org${link.Solutions}`}
                        target="_blank"
                        variant="contained"
                        style={{ margin: "5px" }}
                        color="secondary"
                        fullWidth
                        disabled={!link.Solutions}
                      >
                        {link.Year} {monthData[link.Sitting] || "Unknown"} -{" "}
                        {link.ExamTitle} {getPaperLabel(link.Exam)} - Solutions{" "}
                      </Button>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Box>
          <Typography variant="h6" component="h2" gutterBottom>
            IAI Old Syllabus from 2005 to 2018
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              {IAIExamLinks.filter((link) => link.Year < "2019").map((link) => (
                <>
                  <Grid item xs={12} sm={6}>
                    <Button
                      key={link.ExamPaper}
                      href={`https://actuariesindia.org${link.ExamPaper}`}
                      target="_blank"
                      variant="contained"
                      style={{ margin: "5px" }}
                      fullWidth
                      disabled={!link.Solutions}
                    >
                      {link.Year} {monthData[link.Sitting] || "Unknown"} -{" "}
                      {link.ExamTitle} {getPaperLabel(link.Exam)} - Solutions{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      href={`https://actuariesindia.org${link.Solutions}`}
                      target="_blank"
                      variant="contained"
                      style={{ margin: "5px" }}
                      color="secondary"
                      fullWidth
                      disabled={!link.Solutions}
                    >
                      {link.Year} {monthData[link.Sitting] || "Unknown"} -{" "}
                      {link.ExamTitle} {getPaperLabel(link.Exam)} - Solutions{" "}
                    </Button>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ExamDownloader;
