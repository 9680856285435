import { useEffect, useState } from "react";
import MainHeader from "../components/Main/MainHeader.tsx";
import ExamSearch from "../components/Main/ExamSearch.tsx";
import ExamCorrector from "../components/Main/ExamCorrector.tsx";
import MnemonicCreator from "../components/Main/MnemonicMaker.tsx";
import { createClient } from "@supabase/supabase-js";
import CircularProgress from "@mui/material/CircularProgress";
import FlashcardMaker from "../components/Main/FlashcardMaker.tsx";
import ExamDownloader from "../components/Main/ExamDownloader.tsx";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default function MainPage() {
  const [premiumEnabled, setPremiumEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [userId, setUserId] = useState<string>("");

  const [searchRL, setSearchRL] = useState<number>(0);
  const [examRL, setExamRL] = useState<number>(0);
  const [correctionsRL, setCorrectionsRL] = useState<number>(0);
  const [mnemonicRL, setMnemonicRL] = useState<number>(0);
  const [flashcardRL, setFlashcardRL] = useState<number>(0);

  const [searchResetRL, setSearchResetRL] = useState<string>(
    new Date().toISOString()
  );
  const [examResetRL, setExamResetRL] = useState<string>(
    new Date().toISOString()
  );
  const [correctionsResetRL, setCorrectionsResetRL] = useState<string>(
    new Date().toISOString()
  );
  const [mnemonicResetRL, setMnemonicResetRL] = useState<string>(
    new Date().toISOString()
  );

  const [flashcardResetRL, setFlashcardResetRL] = useState<string>(
    new Date().toISOString()
  );
  const [selectedFeature, setSelectedFeature] = useState<string>("search");

  const formatResetDate = (
    dateString: string,
    daysToAdd: number = 0
  ): string => {
    const date = new Date(dateString);
    date.setUTCDate(date.getUTCDate() + daysToAdd);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchUserAndSubscriptionStatus = async () => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();

        if (user) {
          const userId = user.id;
          setUserId(userId);

          const { data, error } = await supabase.rpc(
            "get_subscription_status",
            {
              user_id: userId,
            }
          );

          if (!error) {
            setPremiumEnabled(data);
          }
        }
      } catch (error) {
        console.log("Error fetching user or subscription status:", error);
        alert(
          "Please refresh this page. If this error persists please contact support@actq.co.uk"
        );
      }
    };

    const fetchRateLimits = async () => {
      try {
        if (premiumEnabled) {
          const {
            data: rate_limits_correction,
            error: rate_limits_correction_error,
          } = await supabase
            .from("rate_limits_correction")
            .select("count, last_reset")
            .maybeSingle();

          if (!rate_limits_correction_error && rate_limits_correction) {
            setCorrectionsRL(rate_limits_correction.count);
            setCorrectionsResetRL(
              formatResetDate(rate_limits_correction.last_reset, 1)
            );
          } else {
            console.log(
              "Error fetching correction rate limits:",
              rate_limits_correction_error
            );
          }
        } else {
          // Fetch for non-premium users
          const { data: rate_limits_exam, error: rate_limits_exam_error } =
            await supabase
              .from("rate_limits_exam")
              .select("count, last_reset")
              .maybeSingle();

          if (!rate_limits_exam_error && rate_limits_exam) {
            setExamRL(rate_limits_exam.count);
            setExamResetRL(formatResetDate(rate_limits_exam.last_reset, 7));
          } else {
            console.log(
              "Error fetching exam rate limits:",
              rate_limits_exam_error
            );
          }

          const { data: rate_limits_search, error: rate_limits_search_error } =
            await supabase
              .from("rate_limits_search")
              .select("count, last_reset")
              .maybeSingle();

          if (!rate_limits_search_error && rate_limits_search) {
            setSearchRL(rate_limits_search.count);
            setSearchResetRL(formatResetDate(rate_limits_search.last_reset, 7));
          } else {
            console.log(
              "Error fetching search rate limits:",
              rate_limits_search_error
            );
          }

          const {
            data: rate_limits_correction,
            error: rate_limits_correction_error,
          } = await supabase
            .from("rate_limits_correction")
            .select("count, last_reset")
            .maybeSingle();

          if (!rate_limits_correction_error && rate_limits_correction) {
            setCorrectionsRL(rate_limits_correction.count);
            setCorrectionsResetRL(
              formatResetDate(rate_limits_correction.last_reset, 0)
            );
          } else {
            console.log(
              "Error fetching correction rate limits:",
              rate_limits_correction_error
            );
          }
        }

        const {
          data: rate_limits_mnemonic,
          error: rate_limits_mnemonic_error,
        } = await supabase
          .from("rate_limits_mnemonic")
          .select("count, last_reset")
          .maybeSingle();

        if (!rate_limits_mnemonic_error && rate_limits_mnemonic) {
          setMnemonicRL(rate_limits_mnemonic.count);
          setMnemonicResetRL(
            formatResetDate(rate_limits_mnemonic.last_reset, 7)
          );
        } else {
          console.log(
            "Error fetching mnemonic rate limits:",
            rate_limits_mnemonic_error
          );
        }
      } catch (error) {
        console.error("Error fetching rate limits:", error);
      }

      const {
        data: rate_limits_flashcard,
        error: rate_limits_flashcard_error,
      } = await supabase
        .from("rate_limits_flashcard")
        .select("count, last_reset")
        .maybeSingle();

      if (!rate_limits_flashcard_error && rate_limits_flashcard) {
        setFlashcardRL(rate_limits_flashcard.count);
        setFlashcardResetRL(
          formatResetDate(rate_limits_flashcard.last_reset, 7)
        );
      } else {
        console.log(
          "Error fetching flashcard rate limits:",
          rate_limits_flashcard_error
        );
      }
    };

    const loadData = async () => {
      await fetchUserAndSubscriptionStatus();
      await fetchRateLimits();
      setLoading(false); // Set loading to false when both functions finish
    };

    loadData();
  }, [premiumEnabled]);

  const handleFeatureSelection = (feature: string) => {
    setSelectedFeature(feature);
  };

  // Show loading spinner while fetching data
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MainHeader
        premiumEnabled={premiumEnabled}
        onFeatureSelect={handleFeatureSelection}
      />
      {selectedFeature === "corrector" ? (
        <ExamCorrector
          premiumEnabled={premiumEnabled}
          correctionsRL={correctionsRL}
          correctionsResetRL={correctionsResetRL}
        />
      ) : selectedFeature === "mnemonic" ? (
        <MnemonicCreator
          premiumEnabled={premiumEnabled}
          mnemonicRL={mnemonicRL}
          mnemonicResetRL={mnemonicResetRL}
        />
      ) : selectedFeature === "flashcard" ? (
        <FlashcardMaker
          premiumEnabled={premiumEnabled}
          userId={userId}
          flashcardRL={flashcardRL}
          flashcardResetRL={flashcardResetRL}
        />
      ) : selectedFeature === "downloader" ? (
        <ExamDownloader />
      ) : (
        <ExamSearch
          premiumEnabled={premiumEnabled}
          searchRL={searchRL}
          searchResetRL={searchResetRL}
          examRL={examRL}
          examResetRL={examResetRL}
        />
      )}
    </>
  );
}
