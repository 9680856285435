import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import searchVideo from "../../assets/ExamSearch.mp4";
import generateVideo from "../../assets/GenerateExamPaper.mp4";
import correctVideo from "../../assets/ExamCorrection.mp4";
import mnemonicVideo from "../../assets/MnemonicMaker.mp4";
import flashcardVideo from "../../assets/Flashcards.mp4";
import flashcardAIVideo from "../../assets/FlashcardsAI.mp4";
import examDownloaderVideo from "../../assets/ExamDownloader.mp4";

const Features = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref5, inView: inView5 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref6, inView: inView6 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref7, inView: inView7 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: ref8, inView: inView8 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  // State for modals
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);
  const [openModal6, setOpenModal6] = useState(false);
  const [openModal7, setOpenModal7] = useState(false);

  // Handlers to open/close modals
  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = () => setOpenModal1(false);

  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);

  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => setOpenModal3(false);

  const handleOpenModal4 = () => setOpenModal4(true);
  const handleCloseModal4 = () => setOpenModal4(false);

  const handleOpenModal5 = () => setOpenModal5(true);
  const handleCloseModal5 = () => setOpenModal5(false);

  const handleOpenModal6 = () => setOpenModal6(true);
  const handleCloseModal6 = () => setOpenModal6(false);

  const handleOpenModal7 = () => setOpenModal7(true);
  const handleCloseModal7 = () => setOpenModal7(false);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
        padding: { xs: 4, md: 8 },
        marginTop: 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography variant="h4" color="text.primary" align="center" gutterBottom>
        Features
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Discover the tools that will transform your study experience.
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: 4,
          width: "100%",
        }}
      >
        {/* Feature 1: AI Question Search */}
        <motion.div
          ref={ref1}
          initial={{ opacity: 0, y: 100 }}
          animate={inView1 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Question Search
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Discover relevant questions quickly with our AI-powered search
                of over 40,000 past questions.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal1}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 2: AI Exam Maker */}
        <motion.div
          ref={ref2}
          initial={{ opacity: 0, y: 100 }}
          animate={inView2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Exam Maker
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Generate customized exams with ease using our AI-driven exam
                maker.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal2}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 3: Exam Corrector */}
        <motion.div
          ref={ref3}
          initial={{ opacity: 0, y: 100 }}
          animate={inView3 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Exam Corrector
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Upload your exam attempt, specify the sitting, and receive
                instant feedback.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal3}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 4: Mnemonic Maker */}
        <motion.div
          ref={ref4}
          initial={{ opacity: 0, y: 100 }}
          animate={inView4 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Mnemonic Maker
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Generate mnemonics and acronyms from words and phrases to
                improve recall.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal4}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 5: Flashcards */}
        <motion.div
          ref={ref5}
          initial={{ opacity: 0, y: 100 }}
          animate={inView5 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Flashcards
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Make and view your own flashcards to help you revise certain
                topics within subjects.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal5}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 6: AI Flashcard Generation */}
        <motion.div
          ref={ref6}
          initial={{ opacity: 0, y: 100 }}
          animate={inView6 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Flashcards Generation
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Use our AI model to generate flashcards based on your notes.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal6}
              >
                See it in action
              </Button>
            </CardContent>
          </Card>
        </motion.div>

        {/* Feature 7: Exam Papers  */}
        <motion.div
          ref={ref7}
          initial={{ opacity: 0, y: 100 }}
          animate={inView7 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                User Friendly Past Paper Downloader
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Download over 2,000 past papers from our user friendly past
                paper page.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                onClick={handleOpenModal7}
              >
                See it in action
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  marginLeft: 2,
                }}
                onClick={() => navigate("/exams")}
              >
                Access it here!
              </Button>
            </CardContent>
          </Card>
        </motion.div>
        {/* Feature 8: Coming Soon  */}
        <motion.div
          ref={ref8}
          initial={{ opacity: 0, y: 100 }}
          animate={inView8 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
              color: theme.palette.text.primary,
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                More features coming soon
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Sign up now and get access to future features, we are always
                trying to improve!
              </Typography>
            </CardContent>
          </Card>
        </motion.div>
      </Box>

      {/* Modal for Feature 1 (AI Question Search) */}
      <Dialog
        open={openModal1}
        onClose={handleCloseModal1}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Question Search</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={searchVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Discover relevant questions quickly with our AI-powered search
              solution designed to optimize your study time. Please note the
              IFOA exam question text cannot be currently shown due to a
              copyright issue. We are working on resolving this issue.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal1}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 2 (AI Exam Maker) */}
      <Dialog
        open={openModal2}
        onClose={handleCloseModal2}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Exam Maker</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={generateVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Generate customized exams with ease using our AI-driven exam
              maker, tailored to your specific learning needs. Please note the
              IFOA exam question text cannot be currently shown due to a
              copyright issue. We are working on resolving this issue.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal2}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 3 (Exam Corrector) */}
      <Dialog
        open={openModal3}
        onClose={handleCloseModal3}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Exam Corrector</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={correctVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Upload your exam attempt, specify the subject and sitting, and
              receive instant feedback. Get detailed corrections and insights on
              areas for improvement to enhance your future performance.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal3}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 4 (Mnemonic Maker) */}
      <Dialog
        open={openModal4}
        onClose={handleCloseModal4}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Mnemonic Maker</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={mnemonicVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Input words and phrases and our AI will turn them into memorable
              mnemonics or acronyms to help you remember during exams.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal4}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 5 (Flashcards) */}
      <Dialog
        open={openModal5}
        onClose={handleCloseModal5}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Flashcards</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={flashcardVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Design and view your own flashcards to help you revise certain
              topics within subjects.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal5}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 6 (AI Flashcards Generator) */}
      <Dialog
        open={openModal6}
        onClose={handleCloseModal6}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>AI Flashcards Generator</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={flashcardAIVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Input your notes from a certain topic and allow our AI model to
              generate flashcards for you to help you revise the topic.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal6}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for Feature 7 (Exam Paper Download) */}
      <Dialog
        open={openModal7}
        onClose={handleCloseModal7}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(8px)",
          },
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
        }}
      >
        <DialogTitle>Exam Paper Downloader</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Lazy-loaded video */}
            <video width="100%" controls>
              <source src={examDownloaderVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Download papers from both the IFOA and IAI in a user friendly
              manner.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleCloseModal7}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Features;
