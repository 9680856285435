import * as React from "react";
import { useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Logo from "../General/Logo";
import { createClient } from "@supabase/supabase-js";
import { useNavigate, useLocation } from "react-router-dom"; // Added useLocation
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const turnstileKey = import.meta.env.VITE_TURNSTILE_SITEKEY as string;

export default function SignIn() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const turnstileRef = useRef<TurnstileInstance | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = React.useState<string | undefined>(
    undefined
  );

  const redirectPath = location.state?.from || "/mainpage";

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string | null;
    const password = data.get("password") as string | null;

    if (!email || !password) {
      setErrorMessage("Email and password must be provided");
      setLoading(false);
      return;
    }

    const emailRegex =
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]|[a-z0-9-]*[a-z0-9]:(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+)\]))$/i;

    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email format");
      setLoading(false);
      return;
    }

    if (!captchaToken) {
      setErrorMessage("Please complete the captcha verification");
      setLoading(false);
      return;
    }

    try {
      const { data: signInData, error } =
        await supabase.auth.signInWithPassword({
          email,
          password,
          options: { captchaToken },
        });

      if (error) {
        setErrorMessage(`Error signing in: ${error.message}`);
        turnstileRef.current?.reset();

        setLoading(false);
      } else if (signInData.session) {
        navigate(redirectPath);
      } else {
        setErrorMessage("No session data returned.");
        turnstileRef.current?.reset();
        setLoading(false);
      }
    } catch {
      setErrorMessage("An unexpected error occurred. Please try again.");
      turnstileRef.current?.reset();
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        backgroundColor: theme.palette.background.default,
        padding: 4,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "150%",
          height: "150%",
          top: "-25%",
          left: "-25%",
          background: `radial-gradient(circle, ${theme.palette.background.default}, ${theme.palette.grey[900]})`,
          transform: "rotate(-25deg)",
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          zIndex: 10,
          backgroundColor: theme.palette.background.default,
          borderRadius: 2,
          padding: { xs: 4, md: 6 },
          boxShadow: theme.shadows[5],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />

        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
            <LockOutlinedIcon />
          </Avatar>
        </motion.div>

        <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
          Sign In
        </Typography>

        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {errorMessage}
          </Alert>
        )}

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.divider,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.light,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.secondary,
                    },
                  }}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.divider,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.light,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.secondary,
                    },
                  }}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Turnstile
                  ref={turnstileRef}
                  siteKey={turnstileKey}
                  options={{ appearance: "always" }}
                  onSuccess={(token) => {
                    setCaptchaToken(token);
                  }}
                />
              </motion.div>
            </Grid>
          </Grid>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Sign In"}
            </Button>
          </motion.div>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Link
                href="/forgotpasswordpage"
                variant="body2"
                sx={{ display: "block", textAlign: "left" }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Link href="/signuppage" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
