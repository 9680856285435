import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { darkTheme } from "./components/General/ThemeOptions";
import HomePage from "./pages/homepage";
import MainPage from "./pages/mainpage";
import SignUpPage from "./pages/signuppage";
import SignInPage from "./pages/signinpage";
import ForgotPasswordPage from "./pages/forgotpasswordpage";
import PricingPage from "./pages/pricingpage";
import CompliancePage from "./pages/compliancepage";
import Contact from "./pages/contactpage";
import ConfirmEmail from "./pages/confirmemailpage";
import ResetPasswordPage from "./pages/resetpasswordpage";
import WelcomePage from "./pages/welcomepage";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import ExamsPage from "./pages/examspage";

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path="pricingpage"
            element={
              <ProtectedRoute>
                <PricingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="mainpage"
            element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<HomePage />} />
          <Route path="homepage" element={<HomePage />} />
          <Route path="exams" element={<ExamsPage />} />
          <Route path="signuppage" element={<SignUpPage />} />
          <Route path="confirmemailpage" element={<ConfirmEmail />} />
          <Route path="welcomepage" element={<WelcomePage />} />
          <Route path="signinpage" element={<SignInPage />} />
          <Route path="forgotpasswordpage" element={<ForgotPasswordPage />} />
          <Route path="resetpasswordpage" element={<ResetPasswordPage />} />
          <Route path="compliancepage" element={<CompliancePage />} />
          <Route path="contactpage" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
