import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../General/Logo";

const HomeHeader = () => {
  const theme = useTheme();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        paddingX: theme.spacing(2),
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Logo />
        </Box>

        {/* Centered Buttons */}
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: theme.spacing(2),
          }}
        >
          <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
            <ScrollLink
              to="features-section"
              smooth={true}
              duration={500}
              offset={-70}
            >
              <Button
                sx={{
                  color: theme.palette.text.primary,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
                variant="outlined"
              >
                Features
              </Button>
            </ScrollLink>
            <ScrollLink
              to="testimonials-section"
              smooth={true}
              duration={500}
              offset={-70}
            >
              <Button
                sx={{
                  color: theme.palette.text.primary,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
                variant="outlined"
              >
                Testimonials
              </Button>
            </ScrollLink>
            <ScrollLink
              to="pricing-section"
              smooth={true}
              duration={500}
              offset={-70}
            >
              <Button
                sx={{
                  color: theme.palette.text.primary,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
                variant="outlined"
              >
                Pricing
              </Button>
            </ScrollLink>
            <Button
              sx={{
                color: theme.palette.text.primary,
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
              variant="outlined"
              href="/exams"
              target="_blank"
            >
              Exams
            </Button>
          </Box>
        </Box>

        {/* Sign In and Sign Up */}
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            alignItems: "center",
            flexGrow: 0,
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                borderColor: theme.palette.secondary.light,
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.secondary.contrastText,
              },
            }}
            component={RouterLink}
            to="/signinpage"
          >
            Sign In
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
              },
            }}
            component={RouterLink}
            to="/signuppage"
          >
            Sign Up
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HomeHeader;
